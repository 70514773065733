import { Button, Chip, Fab, Grid, Paper, Typography, useTheme } from "@mui/material";
import Templete from "../../template/Template";
import { useEffect, useState } from "react";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { api, apiUrl } from "../../services/api";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { Link, useNavigate } from "react-router-dom";
import CardSimple from "../../components/Cards/CardSimple";
import { endMes, formatDate, startMes } from "../../utilities/date";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { ProcessoType } from "../../types/ProcessoType";
import IconMenu from "../../components/Menu/IconMenu";
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SearchIcon from '@mui/icons-material/Search';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ArticleIcon from '@mui/icons-material/Article';
import InputLabel from "../../components/Inputs/InputLabel";
import HistoricoProcesso from "../../modals/processos/HistoricoProcesso";
import SearchProcessos from "./SearchProcessos";
import moment from "moment";
import { isPermite } from "../../utilities/isPermite";

export default function Processos() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [activeProcess, setActiveProcess] = useState<null | ProcessoType>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openLoad, setOpenLoad] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openHistroy, setOpenHistory] = useState(false);
    const [arrHistory, setArrHistory] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [labelFilter, setLabelFilter] = useState("");
    const [nameFilter, setNameFilter] = useState("");
    const [emptyMessage, setEmptyMessage] = useState({ icon: 'text_files', message: "Processos" });
    const [counts, setCounts] = useState({
        cancelados: 0, preparacao: 0, andamento: 0, concluidos: 0
    });
    const columns = [
        {
            id: 'protocolo_processo',
            numeric: false,
            label: 'NUMERO PROCESSO',
        },
        {
            id: 'nome_cliente',
            numeric: false,
            label: 'NOME',
        },
        {
            id: 'causa_processual',
            numeric: false,
            label: 'CAUSA',
        },
        {
            id: 'status_processo',
            numeric: false,
            label: 'STATUS',
        },
        {
            id: 'create_processo',
            numeric: false,
            label: 'DATA CADASTRO',
            format: (dt: any) => formatDate(dt, "brH"),
        },
    ];

    function editProcess() {
        if (activeProcess) {
            if (isPermite("32")) {
                navigate(`/cadastroprocesso?id=${activeProcess.id_processo}`);
            }
        }
    }

    function openDocument() {
        if (activeProcess) {
            setOpenLoad(true);
            setAnchorEl(null);
            api.get(`/createpeticao/${activeProcess.id_processo}`, { responseType: 'blob' }).then(response => {
                console.log(response);
                if (response.status === 204) {
                    return AlertInfo({ icon: 'error', title: "Documento não localizado" });
                }
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${activeProcess.causa_processual} - ${activeProcess.nome_cliente}.docx`;
                link.click();
                window.URL.revokeObjectURL(url);
            });
            setOpenLoad(false);
        }
    }

    function openDocumentRequired(fileDoc: string, nameDoc: string, isComplement = false) {
        if (activeProcess) {
            setOpenLoad(true);
            setAnchorEl(null);
            let docFile = fileDoc;
            if(isComplement){
                if(activeProcess.is_analfabeto) docFile+= '_analfabeto';
                else if(activeProcess.is_incapaz) docFile+= '_incapaz';
            }
            console.log(docFile);
            api.get(`/documentrequerid/${docFile}/${activeProcess.id_processo}`, { responseType: 'blob' }).then(response => {
                if (response.status === 204) {
                    return AlertInfo({ icon: 'error', title: "Documento não localizado" });
                }
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${nameDoc} - ${activeProcess.nome_cliente}.docx`;
                link.click();
                window.URL.revokeObjectURL(url);
            });
            setOpenLoad(false);
        }
    }
    
    function openDocumentRecurso() {
        if (activeProcess) {
            const docFile = activeProcess.tipo_recurso;
            if(docFile.length === 0) return AlertInfo({icon:'info', title: 'Tipo Recurso não Selecionado'})
            openDocumentRequired(encodeURI(`${docFile}_${activeProcess.genero}`), docFile);
        }
    }

    async function openHistoryStatus() {
        if (activeProcess) {
            setOpenLoad(true);
            setAnchorEl(null);
            try {
                const response = await api.get(`/eventosproceso/${activeProcess.id_processo}`);
                if (response.status === 200 && response.data) {
                    if (response.data.error) {
                        AlertInfo({ icon: 'error', title: response.data.message });
                    } else {
                        setArrHistory(response.data);
                        setOpenHistory(true);
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: 'error', title: 'Falha na conexão' });
            }
            setOpenLoad(false);
        }
    }

    function deleteProcess() {
        setAnchorEl(null);
        if(isPermite("33")){
            const text = 'Após deleta-lo ele não aparecerá nos registro de busca';
            AlertConfirm({ icon: 'info', title: 'Deletar Processo?', text }).then(async res => {
                if (res.isConfirmed) {
                    setOpenLoad(true);
                    try {
                        const data = {
                            id_processo: activeProcess?.id_processo,
                            processo_ativo: 0,
                            delete_processo: moment().format("YYYY-MM-DD HH:mm:ss"),
                        };
                        const response = await api.post(`/updateprocesso`, data);
                        if (response && response.status === 200 && response.data) {
                            if (response.data.success) {
                                AlertInfo({ icon: 'success', title: 'PROCESSO DELETADO' }).then(() => {
                                    loadData();
                                });
                            } else if (response.data.error) {
                                AlertInfo({ icon: 'error', title: response.data.message })
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        AlertInfo({ icon: 'error', title: 'Falha na conexão' });
                    }
                    setOpenLoad(false);
                }
            });
        }else{
            AlertInfo({ icon: 'info', title: 'Usuario sem permissão' });            
        }
    }

    const arrMenu = [
        { icon: <EditIcon fontSize="small" color="primary" />, title: "Editar Processo", handle: editProcess },
        { icon: <DescriptionIcon fontSize="small" color="primary" />, title: "Gerar Petição", handle: openDocument },
        
        { icon: <RadioButtonCheckedIcon fontSize="small" color="primary" />, title: "Gerar Recurso/Resposta", handle: () => openDocumentRecurso() },

        { icon: <ChevronRightIcon fontSize="small" color="primary" />, title: "Procuração", handle: () => openDocumentRequired('procuracao', "Procuração", true) },
        // { icon: <ChevronRightIcon fontSize="small" color="primary" />, title: "Procuração Analfabeto", handle: () => openDocumentRequired('procuracao_digital', "Procuração") },
        // { icon: <ChevronRightIcon fontSize="small" color="primary" />, title: "Procuração do Incapaz", handle: () => openDocumentRequired('procuracao_incapaz', "Procuração") },

        { icon: <ViewTimelineIcon fontSize="small" color="primary" />, title: "Declaração de Hipossuficiência", handle: () => openDocumentRequired('contrato_hipossuficiencia', "Contrato de Hipossuficiência", true) },
        // { icon: <ViewTimelineIcon fontSize="small" color="primary" />, title: "Declaração de Hipossuficiência Analfabeto", handle: () => openDocumentRequired('contrato_hipossuficiencia_digital', "Contrato de Hipossuficiência") },
        // { icon: <ViewTimelineIcon fontSize="small" color="primary" />, title: "Declaração de Hipossuficiência Incapaz", handle: () => openDocumentRequired('declaracao_hipossuficiencia_incapaz', "Declaração de Hipossuficiência Incapaz") },
        
        { icon: <CheckCircleIcon fontSize="small" color="primary" />, title: "Contrato INSS", handle: () => openDocumentRequired('contrato_inss', "Contrato INSS", true) },
        // { icon: <CheckCircleIcon fontSize="small" color="primary" />, title: "Contrato INSS Analfabeto", handle: () => openDocumentRequired('contrato_inss_digital', "Contrato INSS") },
        // { icon: <CheckCircleIcon fontSize="small" color="primary" />, title: "Contrato de Honorário do Incapaz", handle: () => openDocumentRequired('contrato_honorario_incapaz', "Contrato de Honorario") },

        { icon: <SensorOccupiedIcon fontSize="small" color="primary" />, title: "Termo de Responsabilidade", handle: () => openDocumentRequired('termo_responsabilidade', "Termo de Responsabilidade") },
        { icon: <AccessTimeIcon fontSize="small" color="primary" />, title: "Historico de Status", handle: openHistoryStatus },
        { icon: <DeleteForeverIcon fontSize="small" color="primary" />, title: "Deletar Processo", handle: deleteProcess }
    ];


    function handleClick(process: ProcessoType, event: any) {
        setActiveProcess(process);
        setAnchorEl(event.currentTarget);
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.post(`/processos`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                else setAllRows(response.data);
                setLabelFilter(`${formatDate(startMes(), "br")} à ${formatDate(endMes(), 'br')}`);
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na Conexão' });
        }
        setOpenLoad(false);
    }

    function filterProcess(cod: number) {
        const filter = allRows.filter((i: ProcessoType) => i.codigo_status_processo === cod);
        setRows(filter);
    }

    useEffect(() => {
        const cancelados = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 1).length;
        const preparacao = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 2).length;
        const andamento = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 3).length;
        const concluidos = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 4).length;
        setCounts({ cancelados, preparacao, andamento, concluidos });
        setRows(allRows);
    }, [allRows]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Templete menuActive="processos">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermite("31") && (
                        <Link to="/cadastroprocesso">
                            <Button
                                variant="contained"
                                fullWidth
                            >
                                NOVO PROCESSO
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end" container alignItems="center">
                    <Chip label={labelFilter} variant="outlined" onDelete={loadData} />
                    <Fab size="medium" color="primary" aria-label="search" onClick={() => setOpenSearch(true)} >
                        <SearchIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="CANCELADOS"
                        txt={counts.cancelados}
                        color="bgError"
                        handle={() => filterProcess(1)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="EM PREPARAÇÃO"
                        txt={counts.preparacao}
                        color="bgPrimary"
                        handle={() => filterProcess(2)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="EM ANDAMENTO"
                        txt={counts.andamento}
                        color="bgBlue"
                        handle={() => filterProcess(3)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="CONCLUIDOS"
                        txt={counts.concluidos}
                        color="bgSuccess"
                        handle={() => filterProcess(4)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        rows={rows}
                        columns={columns}
                        setRows={setRows}
                        handleClick={handleClick}
                        activeOrder="nome_cliente"
                        filter="nome_cliente"
                        title="PROCESSOS"
                    />
                    <IconMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        arrMenu={arrMenu}
                    />
                </Grid>

                <SimpleBackdrop open={openLoad} />
                <HistoricoProcesso
                    open={openHistroy}
                    setOpen={setOpenHistory}
                    historico={arrHistory}
                />
                <SearchProcessos
                    open={openSearch}
                    setOpen={setOpenSearch}
                    setAllRows={setAllRows}
                    setLabelFilter={setLabelFilter}
                />
            </Grid>
        </Templete>
    );
}