import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DrawerApp from "../../components/Drawer/DrawerApp";
import { Button, ListItem } from "@mui/material";
import SelectSimple from "../../components/Selects/SelectSimple";
import InputLabel from "../../components/Inputs/InputLabel";
import moment from "moment";
import { api } from "../../services/api";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { endMes, formatDate, startMes } from "../../utilities/date";

type SearchProcessosProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setAllRows: Dispatch<SetStateAction<never[]>>
    setLabelFilter?: Dispatch<SetStateAction<string>>
}

export default function SearchProcessos({ open, setOpen, setAllRows, setLabelFilter }: SearchProcessosProps) {
    const [openLoad, setOpenLoad] = useState(false);
    const [busca, setBusca] = useState<string | number>("3");
    const [mes, setMes] = useState(moment().format("YYYY-MM"));
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [protocolo_processo, setProtocoloProcesso] = useState("");
    const [cpf_cliente, setCpfCliente] = useState("");
    const [servico_advogado, setServicoAdv] = useState<string | number>("");
    const [causa_processual, setCausa] = useState<string | number>("");
    const [status_processo, setStatusProcesso] = useState<string | number>("");
    const [arrServicos, setArrServicos] = useState([]);
    const [arrCausas, setArrCausas] = useState([]);
    const [arrStatusProcesso, setArrStatusProcesso] = useState([]);

    async function handleSearch() {
        setOpenLoad(true);
        try {
            let start = `0000-00-00`;
            let end = `9999-12-31`;
            if(busca === "1") {
                start = `${formatDate(startMes(mes), "usd")} 00:00:00`; 
                end = `${formatDate(endMes(mes), "usd")} 23:59:59`;
            }
            if(busca === "2") {
                start = `${dataInicial} 00:00:00`;
                end = `${dataFinal} 23:59:59`;
            }
            let data:any = {start, end}
            console.log(data);
            if(busca === "4") data = { ...data, protocolo_processo };
            if(busca === "5") data = { ...data, cpf_cliente };
            if(servico_advogado !== "") data = { ...data, servico_advogado };
            if(causa_processual !== "") data = { ...data, causa_processual };
            if(status_processo !== "") data = { ...data, status_processo };
            const response = await api.post(`/processos`, data);
            if(response.status === 200 && response.data){
                if(response.data.error) AlertInfo({icon: 'error', title: response.data.message});
                else setAllRows(response.data);
                if(setLabelFilter){
                    if(["1", "2"].includes(String(busca))) setLabelFilter(`${formatDate(data.start, "br")} à ${formatDate(data.end, "br")}`);
                    if(busca === "4") setLabelFilter(`Protocolo: ${protocolo_processo}`);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({icon:'error', title: "Falha na conexão"});
        }
        setOpen(false);
        setOpenLoad(false);
    }

    async function loadData() {
        try {
            const response = await api.get(`/optionsprocesso`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) {
                    if(response.data.servicos) setArrServicos(response.data.servicos);
                    if(response.data.causas) setArrCausas(response.data.causas);
                    if(response.data.status_processo) setArrStatusProcesso(response.data.status_processo);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="BUSCAR PROCESSOS"
        >
            <>
                <SimpleBackdrop open={openLoad} />
                <ListItem>
                    <SelectSimple
                        label="Busca"
                        value={busca}
                        setState={setBusca}
                        options={[
                            { value: "1", label: "MÊS" },
                            { value: "2", label: "PERIODO" },
                            { value: "3", label: "SEMPRE" },
                            { value: "4", label: "PROTOCOLO PROCESSO" },
                            { value: "5", label: "CPF CLIENTE" },
                        ]}
                    />
                </ListItem>
                {busca === "1" && (
                    <ListItem>
                        <InputLabel
                            label="Mês"
                            type="month"
                            value={mes}
                            setState={setMes}
                        />
                    </ListItem>
                )}
                {busca === "2" && (
                    <>
                        <ListItem>
                            <InputLabel
                                label="Data Inicio"
                                type="date"
                                value={dataInicial}
                                setState={setDataInicial}
                            />
                        </ListItem>
                        <ListItem>
                            <InputLabel
                                label="Data Fim"
                                type="date"
                                value={dataFinal}
                                setState={setDataFinal}
                            />
                        </ListItem>
                    </>
                )}
                {busca === "4" && (
                    <ListItem>
                        <InputLabel
                            label="Numero do Processo"
                            value={protocolo_processo}
                            setState={setProtocoloProcesso}
                        />
                    </ListItem>
                )}
                {busca === "5" && (
                    <ListItem>
                        <InputLabel
                            label="CPF CLIENTE"
                            type="text"
                            value={cpf_cliente}
                            setState={setCpfCliente}
                            mask="cpf"
                        />
                    </ListItem>
                )}

                {["1", "2", "3"].includes(String(busca)) && (
                    <>
                        <ListItem>
                            <SelectSimple
                                label="Serviços Advocaticios"
                                value={servico_advogado}
                                setState={setServicoAdv}
                                options={arrServicos}
                                isNone
                            />
                        </ListItem>
                        <ListItem>
                            <SelectSimple
                                label="Causa Processual"
                                value={causa_processual}
                                setState={setCausa}
                                options={arrCausas}
                                isNone
                            />
                        </ListItem>
                        <ListItem>
                            <SelectSimple
                                label="Situação Processo"
                                value={status_processo}
                                setState={setStatusProcesso}
                                options={arrStatusProcesso}
                                isNone
                            />
                        </ListItem>
                    </>
                )}

                <ListItem>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSearch}
                    >
                        BUSCAR
                    </Button>
                </ListItem>
            </>
        </DrawerApp>
    );
}