import { Button, Checkbox, Chip, Divider, FormControlLabel, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip } from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalculateIcon from '@mui/icons-material/Calculate';
import { FaTimes } from "react-icons/fa";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Templete from "../../template/Template";
import { useEffect, useState } from "react";
import { ClienteType } from "../../types/ClienteType";
import { ContainerTreeItem, CustomTreeItem } from "../../components/TreeView/TreeView";
import { useTheme } from "@mui/material";
import ListaClientes from "../../modals/clientes/ListaClientes";
import SelectSimple from "../../components/Selects/SelectSimple";
import { api } from "../../services/api";
import InputLabel from "../../components/Inputs/InputLabel";
import { arrayUf } from "../../data/dataUf";
import InputCity from "../../components/Inputs/InputCity";
import CadastroCliente from "../../modals/clientes/CadastroCliente";
import { AlertInfo } from "../../components/Alerts/Alert";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { formatDate, validDate } from "../../utilities/date";
import { converterMoeda, displayMoney } from "../../utilities/displayValues";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProcessoType } from "../../types/ProcessoType";
import Modal from "../../components/Modal/Modal";
import { UsuarioType } from "../../types/UsuarioType";

export default function CadastroProcesso() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoCliente, setInfoCliente] = useState<ClienteType | null>(null);
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalBuscaCliente, setOpenModalBuscaCliente] = useState(false);
    const [openModalCadastroCliente, setOpenModalCadastroCliente] = useState(false);
    const [arrServicos, setArrServicos] = useState([]);
    const [arrCausas, setArrCausas] = useState([]);
    const [arrAllCausas, setArrAllCausas] = useState([]);
    const [arrStatusProcesso, setArrStatusProcesso] = useState([]);
    const [arrCorretores, setArrCorretores] = useState([]);
    const [arrAdvogados, setArrAdvogados] = useState([]);

    const [id_processo, setIdProcesso] = useState<number | null>(null);
    const [servico_advogado, setServicoAdv] = useState<string | number>("");
    const [tipo_previdenciario, setTipoPrevidenciario] = useState<string | number>("");
    const [causa_processual, setCausa] = useState<string | number>("");
    const [status_processo, setStatusProcesso] = useState<string | number>("");
    const [protocolo_processo, setProtocoloProcesso] = useState("");
    const [data_requerimento, setDataRequerimento] = useState("");
    const [numero_beneficio_inss, setNumeroBeneficio] = useState("");
    const [corretor_responsavel, setCorretorResponsavel] = useState<string | number>("");
    const [uf_acao_judicial, setUfAcaoJudicial] = useState<string | number>("PA");
    const [cidade_acao_judicial, setCidadeAcaoJudicial] = useState("");
    const [uf_protocolo, setUfProtocolo] = useState<string | number>("PA");
    const [cidade_protocolo, setCidadeProtocolo] = useState("");
    const [endereco_protocolo, setEnderecoProtocolo] = useState("");
    const [numero_protocolo, setNumeroProtocolo] = useState("");
    const [bairro_protocolo, setBairroProtocolo] = useState("");
    const [cep_protocolo, setCepProtocolo] = useState("");
    const [name_document, setNameDocument] = useState("");
    const [motivo_indeferimento_inss, setMotivoDeferimento] = useState("");
    const [salario_referencia, setSalarioReferencia] = useState("");
    const [salario_retroativo, setSalarioRetroativo] = useState("");
    const [total_causa, setTotalCausa] = useState("");
    const [valor_causa, setValorCausa] = useState("");
    const [id_advogado_processo, setIdAdvogadoCausa] = useState<string | number>("0");
    const [adicional_advogados, setAdicionalAdvogados] = useState<String[]>([]);
    const [data_nascimento_crianca, setDataNascimentoCrianca] = useState("");
    const [nome_crianca, setNomeCrianca] = useState("");
    const [uf_nascimento_crianca, setUfNascimentoCrianca] = useState<string | number>("PA");
    const [cidade_nascimento_crianca, setCidadeNascimentoCrianca] = useState("");
    const [local_nascimento_crianca, setLocalNascimentoCrianca] = useState("");
    const [recurso, setRecurso] = useState(false);
    const [optionsRecurso, setOptionsRecusro] = useState([]);
    const [tipo_recurso, setTipoRecurso] = useState<string | number>("");
    const [data_setenca, setDataSetenca] = useState("");
    const [tribunal, setTribunal] = useState("");

    const [openModalAdv, setOpenModalAdv] = useState(false);


    function handleToggle(cod: number) {
        if (adicional_advogados.filter(i => Number(cod) === Number(i)).length > 0) {
            setAdicionalAdvogados(adicional_advogados.filter(i => Number(cod) !== Number(i)));
        } else {
            setAdicionalAdvogados([...adicional_advogados, String(cod)]);
        }
    }

    async function handleSave() {
        setOpenLoad(true);
        if (Number(id_advogado_processo) === 0) {
            AlertInfo({ icon: 'info', title: 'Selecione um Advogado(a)' });
        } else {
            try {
                let codigo_status_processo = 0;
                if (String(status_processo).length > 0 && arrStatusProcesso.length > 0) {
                    const codStatus = arrStatusProcesso.filter((i: any) => String(i.value) === String(status_processo));
                    if (codStatus.length === 1) codigo_status_processo = codStatus[0]['codigo'];
                }

                const data = {
                    id_processo, id_cliente_processo: infoCliente ? infoCliente.id_cliente : 0,
                    servico_advogado, causa_processual, status_processo, protocolo_processo, codigo_status_processo,
                    tipo_previdenciario, name_document, uf_protocolo, cidade_protocolo, endereco_protocolo,
                    numero_protocolo, bairro_protocolo, cep_protocolo,
                    data_requerimento: validDate(data_requerimento), numero_beneficio_inss, corretor_responsavel,
                    uf_acao_judicial, cidade_acao_judicial, motivo_indeferimento_inss,
                    salario_referencia: converterMoeda(salario_referencia),
                    salario_retroativo: converterMoeda(salario_retroativo),
                    total_causa: converterMoeda(total_causa),
                    valor_causa: converterMoeda(valor_causa), nome_crianca,
                    uf_nascimento_crianca, cidade_nascimento_crianca,
                    id_advogado_processo, data_nascimento_crianca: validDate(data_nascimento_crianca),
                    local_nascimento_crianca,
                    recurso: Number(recurso), tipo_recurso, data_setenca: validDate(data_setenca), tribunal,
                    adicional_advogados: adicional_advogados.toString(),
                    update_processo: formatDate(new Date, 'usdH'),
                };
                const channel = id_processo ? `/updateprocesso` : `/createprocesso`;
                const response = await api.post(channel, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.success) {
                        AlertInfo({ icon: 'success', title: 'DADOS GRAVADOS' }).then(() => {
                            navigate(`/processos`);
                        });
                    } else if (response.data.error) {
                        AlertInfo({ icon: 'error', title: response.data.message })
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: 'error', title: 'Falha na conexão' });
            }
        }
        setOpenLoad(false);
    }

    function selectCliente() {
        if (infoCliente === null) setOpenModalBuscaCliente(true);
        else if (infoCliente && infoCliente.id_cliente) setOpenModalCadastroCliente(true);
    }

    function setDataResponse(processo: ProcessoType) {
        setIdProcesso(processo.id_processo);
        setInfoCliente(processo);
        setServicoAdv(processo.servico_advogado);
        setTipoPrevidenciario(processo.tipo_previdenciario);
        setCausa(processo.causa_processual);
        setStatusProcesso(processo.status_processo);
        setProtocoloProcesso(processo.protocolo_processo);
        setNameDocument(processo.name_document);
        setUfProtocolo(processo.uf_protocolo);
        setCidadeProtocolo(processo.cidade_protocolo);
        setEnderecoProtocolo(processo.endereco_protocolo);
        setNumeroProtocolo(processo.numero_protocolo);
        setBairroProtocolo(processo.bairro_protocolo);
        setCepProtocolo(processo.cep_protocolo);
        if (processo.data_requerimento) setDataRequerimento(formatDate(processo.data_requerimento, "usd"));
        setNumeroBeneficio(processo.numero_beneficio_inss);
        setCorretorResponsavel(processo.corretor_responsavel);
        setUfAcaoJudicial(processo.uf_acao_judicial);
        setCidadeAcaoJudicial(processo.cidade_acao_judicial);
        setMotivoDeferimento(processo.motivo_indeferimento_inss);
        if (processo.salario_referencia > 0) setSalarioReferencia(displayMoney(processo.salario_referencia));
        if (processo.salario_retroativo > 0) setSalarioRetroativo(displayMoney(processo.salario_retroativo));
        if (processo.total_causa > 0) setTotalCausa(displayMoney(processo.total_causa));
        if (processo.valor_causa > 0) setValorCausa(displayMoney(processo.valor_causa));
        setIdAdvogadoCausa(processo.id_advogado_processo);
        if (processo.data_nascimento_crianca) setDataNascimentoCrianca(formatDate(processo.data_nascimento_crianca, "usd"));
        setLocalNascimentoCrianca(processo.local_nascimento_crianca);
        setNomeCrianca(processo.nome_crianca);
        setUfNascimentoCrianca(processo.uf_nascimento_crianca);
        setCidadeNascimentoCrianca(processo.cidade_nascimento_crianca);
        setRecurso(Boolean(processo.recurso));
        setTipoRecurso(processo.tipo_recurso);
        setTribunal(processo.tribunal);
        if (processo.data_setenca) setDataSetenca(formatDate(processo.data_setenca, "usd"));
        if (String(processo.adicional_advogados).length > 0) setAdicionalAdvogados(String(processo.adicional_advogados).split(','));
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/optionsprocesso`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) {
                    if (response.data.servicos) setArrServicos(response.data.servicos);
                    if (response.data.causas) {
                        setArrAllCausas(response.data.causas);
                        setArrCausas(response.data.causas);
                    }
                    if (response.data.status_processo) setArrStatusProcesso(response.data.status_processo);
                    if (response.data.corretores) {
                        const optCorretores = response.data.corretores;
                        setArrCorretores(optCorretores.map((c: any) => ({ ...c, value: c.abreviacao_corretor, label: c.abreviacao_corretor })));
                    }
                    if (response.data.advogados) {
                        const optAdv = response.data.advogados;
                        setArrAdvogados(optAdv.map((c: any) => ({ ...c, value: c.id_usuario, label: c.nome_usuario })));
                    }
                    if (response.data.recurso) {
                        setOptionsRecusro(response.data.recurso);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    async function loadProcesso() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_processo: id };
                const response = await api.post(`/processo`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                    else setDataResponse(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: "error", title: 'NÃO POSSIVEL BUSCAR DADOS' });
            }
        }
        setOpenLoad(false);
    }

    function handleRemove() {
        setInfoCliente(null);
    }

    function calcValorCausaX12() {
        const salRef = converterMoeda(salario_referencia);
        const salRet = converterMoeda(salario_retroativo);
        if (salRef > 0 && salRet > 0) {
            const calc = salRet + (salRef * 12);
            const calcRef = salRef * 60;
            setTotalCausa(displayMoney(calc));
            setValorCausa(displayMoney(calc > calcRef ? calcRef : calc));
        }
    }

    useEffect(() => {
        if (servico_advogado === "") {
            // setArrCausas(arrAllCausas);
        } else {
            const filterCausas = arrAllCausas.filter((i: any) => i.association === servico_advogado);
            setArrCausas(filterCausas);
        }
    }, [servico_advogado, tipo_previdenciario]);

    useEffect(() => {
        let nd = "";
        if (servico_advogado === "PREVIDENCIÁRIO" && tipo_previdenciario === "ADMINSTRATIVO") nd = "Administrativo ";
        nd += causa_processual;
        if (infoCliente && infoCliente.genero) nd += `_${infoCliente.genero}`;
        setNameDocument(nd);
    }, [causa_processual, infoCliente, tipo_previdenciario, servico_advogado]);

    useEffect(() => {
        loadData();
        loadProcesso();
    }, []);

    return (
        <Templete menuActive="processos">
            <Paper elevation={4} sx={{ padding: 4 }}>
                <SimpleBackdrop open={openLoad} />
                <Modal
                    open={openModalAdv}
                    setOpen={setOpenModalAdv}
                    title="SELECIONAR ADVOGADOS"
                    buttonActions={[{ color: 'primary', text: 'FECHAR', handle: () => setOpenModalAdv(false) }]}
                >
                    <List>
                        {arrAdvogados.map((adv: UsuarioType, ind) => (
                            <ListItemButton
                                key={`adv${ind}`}
                                role="listitem"
                                onClick={() => handleToggle(adv.id_usuario)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={adicional_advogados.filter(i => Number(adv.id_usuario) === Number(i)).length > 0}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={adv.nome_usuario} />
                            </ListItemButton>
                        ))}
                    </List>
                </Modal>
                <ListaClientes
                    open={openModalBuscaCliente}
                    setOpen={setOpenModalBuscaCliente}
                    setInfoCliente={setInfoCliente}
                />
                <CadastroCliente
                    open={openModalCadastroCliente}
                    setOpen={setOpenModalCadastroCliente}
                    cliente_id={infoCliente ? infoCliente.id_cliente : null}
                />
                <Grid container spacing={1}>
                    <Grid item xs={11} sm={5}>
                        <ContainerTreeItem
                            selectedItems="containerCliente"
                        >
                            <CustomTreeItem
                                itemId="containerCliente"
                                label={infoCliente ? infoCliente.nome_cliente : "Selecione o cliente"}
                                onClick={selectCliente}
                                color="#fff"
                                bgColor={infoCliente ? theme.palette.success.main : theme.palette.error.main}
                                labelIcon={AccountBoxIcon}
                            >
                            </CustomTreeItem>
                        </ContainerTreeItem>
                    </Grid>
                    <Grid item xs={1}>
                        {infoCliente && (
                            <Tooltip title="Remover Cliente">
                                <IconButton onClick={handleRemove}>
                                    <FaTimes color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12} sm={servico_advogado === "PREVIDENCIÁRIO" ? 3 : 4}>
                        <SelectSimple
                            label="Serviços Advocaticios"
                            value={servico_advogado}
                            setState={setServicoAdv}
                            options={arrServicos}
                            isNone
                        />
                    </Grid>
                    {servico_advogado === "PREVIDENCIÁRIO" && (
                        <Grid item xs={12} sm={3}>
                            <SelectSimple
                                label="Tipo Previdenciário"
                                value={tipo_previdenciario}
                                setState={setTipoPrevidenciario}
                                options={[
                                    { value: 'JUDICIARIO', label: 'JUDICIARIO' },
                                    { value: 'ADMINSTRATIVO', label: 'ADMINSTRATIVO' },
                                ]}
                                isNone
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={servico_advogado === "PREVIDENCIÁRIO" ? 3 : 4}>
                        <SelectSimple
                            label="Causa Processual"
                            value={causa_processual}
                            setState={setCausa}
                            options={arrCausas}
                            isNone
                        />
                    </Grid>
                    <Grid item xs={12} sm={servico_advogado === "PREVIDENCIÁRIO" ? 3 : 4}>
                        <SelectSimple
                            label="Situação Processo"
                            value={status_processo}
                            setState={setStatusProcesso}
                            options={arrStatusProcesso}
                            isNone
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="protocolo_processo"
                            label="Número do Processo"
                            value={protocolo_processo}
                            setState={setProtocoloProcesso}
                            next="data_requerimento"
                        />
                    </Grid>
                    {(servico_advogado === "ASSISTENCIAL" || servico_advogado === "PREVIDENCIÁRIO" && tipo_previdenciario === "JUDICIARIO") && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="data_requerimento"
                                    type="date"
                                    label="Data do Requerimento"
                                    value={data_requerimento}
                                    setState={setDataRequerimento}
                                    next="numero_beneficio_inss"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="numero_beneficio_inss"
                                    label="Numero Beneficio INSS"
                                    value={numero_beneficio_inss}
                                    setState={setNumeroBeneficio}
                                    next="corretor_responsavel"
                                />
                            </Grid>
                        </>
                    )}

                    {servico_advogado === "PREVIDENCIÁRIO" && tipo_previdenciario === "ADMINSTRATIVO" && (
                        <>
                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="Informações da agência do INSS" size="small" />
                                </Divider>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <SelectSimple
                                    label="UF"
                                    id="uf_protocolo"
                                    value={uf_protocolo}
                                    setState={setUfProtocolo}
                                    options={arrayUf}
                                    next="cidade_protocolo"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputCity
                                    label="Cidade"
                                    id="cidade_protocolo"
                                    uf={uf_protocolo}
                                    value={cidade_protocolo}
                                    setValue={setCidadeProtocolo}
                                    next="endereco_protocolo"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    id="endereco_protocolo"
                                    label="Endereço"
                                    value={endereco_protocolo}
                                    setState={setEnderecoProtocolo}
                                    next="numero_protocolo"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputLabel
                                    id="numero_protocolo"
                                    label="Numero"
                                    value={numero_protocolo}
                                    setState={setNumeroProtocolo}
                                    next="bairro_protocolo"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="bairro_protocolo"
                                    label="Bairro"
                                    value={bairro_protocolo}
                                    setState={setBairroProtocolo}
                                    next="cep_protocolo"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="cep_protocolo"
                                    label="Cep"
                                    value={cep_protocolo}
                                    setState={setCepProtocolo}
                                    next="cidade_acao_judicial"
                                    mask="cep"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label={<KeyboardArrowUpIcon />} size="small" />
                                </Divider>
                            </Grid>
                        </>
                    )}

                    {(servico_advogado === "ASSISTENCIAL" || servico_advogado === "PREVIDENCIÁRIO" && tipo_previdenciario === "JUDICIARIO") && (
                        <>
                            <Grid item xs={12} sm={2}>
                                <SelectSimple
                                    label="UF Ação Judicial"
                                    id="uf_acao_judicial"
                                    value={uf_acao_judicial}
                                    setState={setUfAcaoJudicial}
                                    options={arrayUf}
                                    next="cidade_acao_judicial"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputCity
                                    label="Cidade Ação Judicial"
                                    id="cidade_acao_judicial"
                                    uf={uf_acao_judicial}
                                    value={cidade_acao_judicial}
                                    setValue={setCidadeAcaoJudicial}
                                    next="motivo_indeferimento_inss"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    id="motivo_indeferimento_inss"
                                    label="Motivo Indeferimento INSS"
                                    value={motivo_indeferimento_inss}
                                    setState={setMotivoDeferimento}
                                    next="salario_retroativo"
                                />
                            </Grid>
                        </>
                    )}

                    {causa_processual !== "" && !String(causa_processual).includes("Maternidade") && (
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="salario_retroativo"
                                    label="Saldo Retroativo"
                                    value={salario_retroativo}
                                    setState={setSalarioRetroativo}
                                    next="salario_referencia"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="salario_referencia"
                                    label="Salario Referência"
                                    value={salario_referencia}
                                    setState={setSalarioReferencia}
                                    next="total_causa"
                                    isButton
                                    iconButton={<CalculateIcon />}
                                    exeButton={calcValorCausaX12}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="total_causa"
                                    label="Total da Causa"
                                    value={total_causa}
                                    setState={setTotalCausa}
                                    next="valor_causa"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="valor_causa"
                                    label="Valor da Causa"
                                    value={valor_causa}
                                    setState={setValorCausa}
                                    next="id_advogado_processo"
                                />
                            </Grid>
                        </Grid>
                    )}


                    {String(causa_processual).includes("Maternidade") && (
                        <>
                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label="Informações da Criança" size="small" />
                                </Divider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    id="nome_crianca"
                                    label="Nome da Criança"
                                    value={nome_crianca}
                                    setState={setNomeCrianca}
                                    next="local_nascimento_crianca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    id="local_nascimento_crianca"
                                    label="Local de Nascimento"
                                    value={local_nascimento_crianca}
                                    setState={setLocalNascimentoCrianca}
                                    next="cidade_nascimento_crianca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <SelectSimple
                                    label="UF"
                                    id="uf_nascimento_crianca"
                                    value={uf_nascimento_crianca}
                                    setState={setUfNascimentoCrianca}
                                    options={arrayUf}
                                    next="cidade_nascimento_crianca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputCity
                                    label="Cidade"
                                    id="cidade_nascimento_crianca"
                                    uf={uf_nascimento_crianca}
                                    value={cidade_nascimento_crianca}
                                    setValue={setCidadeNascimentoCrianca}
                                    next="data_nascimento_crianca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="data_nascimento_crianca"
                                    type="date"
                                    label="Data de Nascimento"
                                    value={data_nascimento_crianca}
                                    setState={setDataNascimentoCrianca}
                                    next="valor_causa"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="valor_causa"
                                    label="Valor da Causa"
                                    value={valor_causa}
                                    setState={setValorCausa}
                                    next="id_advogado_processo"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider>
                                    <Chip label={<KeyboardArrowUpIcon />} size="small" />
                                </Divider>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={3}>
                        <SelectSimple
                            id="corretor_responsavel"
                            label="Corretor Responsavel"
                            value={corretor_responsavel}
                            setState={setCorretorResponsavel}
                            options={arrCorretores}
                            next="cidade_acao_judicial"
                            isNone
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <SelectSimple
                            id="id_advogado_processo"
                            label="Advogado(a)"
                            value={id_advogado_processo}
                            setState={setIdAdvogadoCausa}
                            options={arrAdvogados}
                            isNoneNum
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} container alignItems="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => setOpenModalAdv(true)}
                        >
                            MAIS ADVOGADOS
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} container alignItems="flex-end" justifyContent="center">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recurso}
                                    onChange={() => setRecurso(!recurso)}
                                />
                            }
                            label="RECURSO"
                        />
                    </Grid>

                    {recurso && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <SelectSimple
                                    id="tipo_recurso"
                                    label="Tipo Recurso"
                                    value={tipo_recurso}
                                    setState={setTipoRecurso}
                                    options={optionsRecurso}
                                    next="data_setencao"
                                    isNone
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="data_setenca"
                                    type="date"
                                    label="Data Setença"
                                    value={data_setenca}
                                    setState={setDataSetenca}
                                    next="tribunal"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="tribunal"
                                    type="text"
                                    label="Tribunal"
                                    value={tribunal}
                                    setState={setTribunal}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={false} sm={12}></Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="success"
                            fullWidth
                            onClick={handleSave}
                        >
                            SALVAR PROCESSO
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                </Grid>
            </Paper>
        </Templete>
    );
}