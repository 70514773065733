import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import InputLabel from '../Inputs/InputLabel';

type ColumnsTableProps = {
    id: string | number
    label: string
    align?: "center" | "left" | "right"
    minWidth?: number
    numeric?: boolean
    format?: (a: any) => string
    component?: ({ item }: any) => JSX.Element | null
    orderColum?: string
}

type TableDataProps = {
    columns: ColumnsTableProps[],
    rows: any[],
    setRows?: any,
    activeOrder?: string | number
    handleClick?: (a: any, event?: any | undefined) => void,
    defaultRows?: number
    isPagination?: boolean,
    filter?: string,
    title?: string
}

type EnhancedTableToolbarProps = {
    rows: any[],
    setRows: any,
    column: string,
    title?: string
};

function EnhancedTableToolbar({rows, setRows, column, title}: EnhancedTableToolbarProps) {
    const [filter, setFilter] = useState("");   

    function handleFilter(){
        if(filter.length > 0){
            const filterRows = rows.filter((i:any) => String(i[column]).includes(filter.toUpperCase()));
            setRows(filterRows);
        }else{
            setRows(rows);
        }
    }

    return (
        <Toolbar
            sx={[
                {
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                }
            ]}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h5"
                id="tableTitle"
                component="div"
            >
                <DriveFileMoveIcon /> &nbsp;
                {title}
            </Typography>
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            />

            <Tooltip title="Filter list">
                <>
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                    <InputLabel
                        placeholder='Filtrar'
                        value={filter}
                        setState={setFilter}
                        isUpper
                        onKeyUp={e => {
                            if(e.key === 'Enter') handleFilter();
                        }}
                    />
                </>
            </Tooltip>
        </Toolbar>
    );
}

export default function TableDataSimple({ columns, rows, handleClick = () => { }, defaultRows = 100, isPagination = true, activeOrder, filter, title }: TableDataProps) {
    const [page, setPage] = useState(0);
    const [orderActive, setOrderActive] = useState<string | number | undefined>(activeOrder || undefined);
    const [orderDirection, setOrderDirection] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRows);
    const [allRows, setAllRows] = useState(rows);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const orderByList = (idColumn: string | number) => {
        if (allRows.length > 0) {
            const sortRows = allRows.sort((a, b) => {
                return a[idColumn] < b[idColumn] ? -1 : a[idColumn] > b[idColumn] ? 1 : 0;
            });

            if (idColumn !== orderActive) {
                if (orderDirection) setAllRows(sortRows);
                else setAllRows(sortRows.reverse());
                setOrderActive(idColumn);
            } else {
                const alterDirection = !orderDirection;
                if (alterDirection) setAllRows(sortRows);
                else setAllRows(sortRows.reverse());
                setOrderDirection(alterDirection);
            }
        }
    }

    useEffect(() => {
        setPage(0);
    }, [allRows]);
    
    useEffect(() => {
        setAllRows(rows);
    }, [rows]);

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {filter && (
                <EnhancedTableToolbar rows={rows} setRows={setAllRows} column={filter} title={title} />
            )}
            <TableContainer sx={{ maxHeight: 440, width: { sm: '100%', xs: 300 } }}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, textAlign: column.numeric ? 'right' : 'left' }}
                                    sortDirection={false}
                                >
                                    <TableSortLabel
                                        active={column.id === orderActive ? true : false}
                                        direction={orderDirection ? 'asc' : 'desc'}
                                        onClick={() => orderByList(column.id)}
                                    >

                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allRows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, indexR) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={`row${indexR}`}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            const format = column.format ? column.format : (txt: string | number) => txt;
                                            const Component = column.component ? column.component : null;
                                            return (
                                                <TableCell
                                                    key={column.id}
                                                    style={{ minWidth: column.minWidth, textAlign: column.numeric ? 'right' : 'left' }}
                                                    onClick={e => handleClick(row, e)}
                                                >
                                                    {!Component && format(value)}
                                                    {Component && <Component item={value} />}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPagination && (
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={allRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Registros por pagina"
                    labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count}`}
                />
            )}
        </Paper>
    );
}
